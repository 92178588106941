import './App.css';
import React, { useEffect, useState } from "react";
import {Form, Row, Col, Button, Container } from 'react-bootstrap';

function App() {

  return (
    <div className="App">
      <div className="first-section">
        <div>
          <h1 className="title1">Kauzlaričovi</h1>
          <h2 className="title2">Terka</h2>
          <h2 className="title3">A</h2>
          <h2 className="title4">Ivo</h2>
        </div>
        <Container>
          <Row style={{marginTop: "50px", marginBottom: "50px"}}>
          </Row>
        </Container>
      </div>
      <div className='second-section'>
        <div className='center-content'>
          <h1 className='title'><b>Fotografie</b></h1>
          <h2 className='subtitle'>Děkujeme všem příbuzným a přátelům, že se zúčastnili naší svatby.<br/>Nyní Vám dáme možnost prohlédnout si svatební fotografie <a href='https://onedrive.live.com/?authkey=%21AAOdh%5Fu6kHh3ObM&id=A0EE6F94B5B61843%21156740&cid=A0EE6F94B5B61843' target='_blank'>zde</a>.</h2>             
        </div>
      </div>
      <div className='fifth-section'>
        <img src='erb.svg' style={{maxWidth: '100%'}}></img>
      </div>
    </div>
  );
}

export default App;